// Learning Center Admin Calls
// BASE CONFIGURATION
const targetURL = {
    development: {
        // baseURL: "http://localhost:8080/graphql",
        baseURL: "https://cellcore-learning-center.azurewebsites.net/graphql",
    },
    production: {
        baseURL: "https://cellcore-learning-center.azurewebsites.net/graphql",
    },
}[process.env.NODE_ENV];

export const onlyQuery = (query, signal) => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };
    let result = fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        signal: signal,
        body: JSON.stringify({
            query,
        }),
    })
        .then((res) => res.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });

    return result;
};

export const queryAndVariables = (query, variables) => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };
    let result = fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            query,
            variables,
        }),
    })
        .then((res) => res.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });

    return result;
};

//SECTION HTTP/AJAX CALLS

export const getAllSections = async () => {
    const query = `
                query {
                    sections(page: 0, size: 50 ) {
                        count
                        rows {
                            id
                            title
                            columns
                            order
                            ts_created
                            is_archived
                        }
                        totalPages
                        currentPage
                    }
                }
            `;

    let result = await onlyQuery(query);

    return result;
};

export const getSectionById = async (variables) => {
    const query = `
        query Section($id: Int!) {
            section(id: $id) {
                id
                title
                columns
                order
                status
            }
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const createSection = async (variables) => {
    const query = `
                mutation CreateSection($title: String, $columns: Int, $order: Int, $is_archived: Boolean, $status: Int) {
                    createSection(title: $title, columns: $columns, order: $order, is_archived: $is_archived, status: $status) {
                        id
                        title
                        columns
                        order
                        is_archived
                        ts_created
                    }
                }
            `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const editSection = async (variables) => {
    const query = `
                mutation UpdateSection($id: Int!, $title: String, $columns: Int, $order: Int, $is_archived: Boolean, $status: Int) {
                    updateSection(id: $id, title: $title, columns: $columns, order: $order, is_archived: $is_archived, status: $status) {
                        id
                        title
                        columns
                        order
                        is_archived
                        ts_created
                    }
                }
            `;

    let updateSection = await queryAndVariables(query, variables);

    return updateSection;
};

export const deleteSection = async (variables) => {
    const query = `
        mutation DeleteSection($id: Int!){
            deleteSection(id: $id) 
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

// TOPIC HTTPS/AJAX CALLS

export const topicsBySectionId = async (variables) => {
    const query = `
                query AllTopics($section_id: Int!) {
                    topicsAndModules(section_id: $section_id ) {
                        count
                        rows{
                            id
                            section_id
                            title
                            short_desc
                            long_desc
                            thumb_30
                            thumb_60
                            thumb_360
                            order
                            is_archived
                            ts_created
                            ts_updated
                            section{
                                id
                                title
                            }
                            status
                            module{
                                id
                                title
                            }
                        }
                        totalPages
                        currentPage
                    }
                }
            `;
    let result = await queryAndVariables(query, variables);

    return result;
};

export const getTopicById = async (variables) => {
    const query = `
        query Topic($id: Int!) {
            topic(id: $id) {
                id
                title
                order
                status
                thumb_360
                short_desc
                long_desc
            }
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const createTopic = async (variables) => {
    const query = `
            mutation CreateTopic($section_id: Int, $title: String, $short_desc: String, $long_desc: String $order: Int, $status: Int, $thumb_360: String) {
                createTopic(section_id: $section_id, title: $title, short_desc: $short_desc, long_desc: $long_desc, thumb_360: $thumb_360 order: $order, status: $status){
                    id
                    title
                    thumb_360
                    short_desc
                    long_desc
                    thumb_30        
                    thumb_360       
                    is_archived     
                    ts_created      
                    ts_updated      
                    status      
                    section{
                        id
                        title
                    }
                    section_id
                    order
                    status
                }
            }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const editTopic = async (variables) => {
    const query = `
            mutation UpdateTopic($id: Int!,$section_id: Int, $title: String, $short_desc: String, $long_desc: String, $thumb_360: String, $order: Int ) {
                updateTopic(id:$id, section_id: $section_id, title: $title, short_desc: $short_desc, long_desc: $long_desc,  thumb_360: $thumb_360, order: $order){
                    id
                    title
                    thumb_360
                    short_desc
                    long_desc
                    thumb_30        
                    thumb_360       
                    is_archived     
                    ts_created      
                    ts_updated      
                    status      
                    section{
                        id
                        title
                    }
                    section_id
                    order
                    status
                }
            }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const deleteTopic = async (variables) => {
    const query = `
        mutation DeleteTopic($id: Int!){
            deleteTopic(id: $id) 
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

// Modules HTTP/AJAX

export const getModules = async (variables) => {
    const query = `
                query Modules( $topic_id: Int!) {
                  modules( topic_id: $topic_id, page: 0, size: 50) {
                      count
                      rows{
                        id
                        section_id
                        topic_id
                        title
                        desc
                        thumb_30
                        thumb_60
                        thumb_360
                        order
                        ts_created
                        ts_updated
                        is_archived
                        status
                        topic{
                            id
                            title
                        }
                      }
                      totalPages
                      currentPage
                  }
                }
            `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const getModuleById = async (variables) => {
    const query = `
        query Module($id: Int!) {
            module(id: $id) {
                id
                section_id
                topic_id
                title
                desc
                thumb_360
                order
                status
                topic{
                    id
                    title
                }
            }
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const createModule = async (variables) => {
    const query = `
            mutation CreateModule($section_id: Int, $topic_id: Int!, $desc:String!, $title: String, $order: Int, $is_archived: Boolean, $status: Int, $thumb_360: String) {
                createModule(section_id: $section_id, topic_id: $topic_id, desc: $desc, title: $title, order: $order, is_archived: $is_archived, status: $status, thumb_360: $thumb_360) {
                    id
                    section_id
                    topic_id
                    title
                    desc
                    thumb_360
                    order
                    is_archived
                    ts_created
                    topic{
                        id
                        title
                    }
              }
          }
        `;
    let result = await queryAndVariables(query, variables);

    return result;
};

export const updateModule = async (variables) => {
    const query = `
             mutation UpdateModule($id: Int!, $section_id: Int, $topic_id: Int, $title: String, $desc: String, $thumb_360: String, $order: Int) {
                updateModule(id: $id, section_id: $section_id, topic_id: $topic_id title: $title, desc: $desc, thumb_360: $thumb_360, order: $order){
                    id
                    title
                    thumb_360
                    desc
                    thumb_30        
                    thumb_360       
                    is_archived     
                    ts_created      
                    ts_updated      
                    status      
                    topic{
                        id
                        title
                    }
                    section_id
                    order
                    status
                }
            }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const deletedModule = async (variables) => {
    const query = `
        mutation DeleteModule($id: Int!){
            deleteModule(id: $id) 
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

//Articles Calls

export const getArticles = async (variables) => {
    const query = `
                query Articles($section_id: Int, $topic_id: Int!, $module_id: Int!){
                  articles(section_id: $section_id, topic_id: $topic_id, module_id: $module_id, page: 0, size: 50) {
                    count
                    rows {
                      id
                      section_id
                      topic_id
                      module_id
                      title
                      desc
                      video_id
                      thumb_30
                      thumb_60
                      thumb_360
                      order
                      ts_created
                      ts_updated
                      is_archived
                      status
                      module {
                          id
                          title
                      }
                    }
                    totalPages
                    currentPage
                  }
                }
            `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const articlesById = async (variables) => {
    const query = `
                query Article($id: Int!){
                  article(id: $id) {
                    id 
                    section_id
                    topic_id
                    module_id
                    title
                    desc
                    video_id
                    thumb_30
                    thumb_60
                    thumb_360
                    order
                    ts_created
                    ts_updated
                    is_archived
                    status
                    module{
                      id
                      title
                    }
                  }
                }
            `;
    let result = await queryAndVariables(query, variables);

    return result;
};

export const createArticle = async (variables) => {
    const query = `
            mutation CreateArticle($section_id: Int, $topic_id: Int!, $module_id: Int! $desc:String!, $title: String, $order: Int, $is_archived: Boolean, $status: Int, $video_id: Int, $duration: Int) {
              createArticle(section_id: $section_id, topic_id: $topic_id, module_id: $module_id, desc: $desc, title: $title, order: $order, is_archived: $is_archived, status: $status, video_id: $video_id, duration: $duration) {
                  id
                  section_id
                  topic_id
                  title
                  desc
                  video_id
                  duration
                  thumb_360
                  order
                  is_archived
                  ts_created
                  module{
                      id
                      title
                  }
              }
          }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const updateArticle = async (variables) => {
    const query = `
                mutation UpdateArticle($id: Int!, $section_id: Int, $topic_id: Int, $module_id: Int $desc:String, $title: String, $video_id: Int, $order: Int, $duration: Int) {
                  updateArticle(id: $id, section_id: $section_id, topic_id: $topic_id, module_id: $module_id, desc: $desc, title: $title, video_id: $video_id, order: $order, duration: $duration) {
                      id
                      section_id
                      topic_id
                      title
                      desc
                      video_id
                      duration
                      thumb_360
                      order
                      is_archived
                      ts_created
                      module{
                          id
                          title
                      }
                  }
              }
            `;

    let result = await queryAndVariables(query, variables);

    return result;
};
export const deleteArticle = async (variables) => {
    const query = `
        mutation DeleteArticle($id: Int!){
            deleteArticle(id: $id) 
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};

// Resource Calls

export const getResources = async (variables) => {
    const query = `
                query Resources($article_id: Int!){
                  resources(article_id: $article_id) {
                    count
                    rows {
                      id
                      article_id
                      type
                      title
                      desc
                      url
                      target
                      video_id
                      long_desc
                      thumb_30
                      thumb_60
                      thumb_360
                      order
                      ts_created
                      ts_updated
                      is_archived
                      status
                      article{
                        id 
                        title
                      }
                    }
                    totalPages
                    currentPage
                  }
                }
            `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const resourceById = async (variables) => {
    const query = `
        query Resourc($id: Int!){
          resource(id: $id) {
            id
            article_id
            type
            title
            desc
            url
            target
            video_id
            long_desc
            thumb_30
            thumb_60
            thumb_360
            order
            ts_created
            ts_updated
            is_archived
            status
          }
        }
      `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const createResource = async (variables) => {
    const query = `
            mutation CreateResource(
                $article_id: Int!,
                $type: String!,
                $title: String!,
                $desc: String!,
                $url: String,
                $target: Int,
                $order: Int,
                $is_archived: Boolean,
                $status: Int,
                $thumb_360: String,
                $video_id: Int
                ) {
                createResource(
                    article_id: $article_id,
                    type:$type,
                    title: $title,
                    desc: $desc,
                    url: $url,
                    target: $target,
                    order: $order,
                    is_archived: $is_archived,
                    status: $status,
                    thumb_360: $thumb_360
                    video_id: $video_id
                    ) {
                        id
                        article_id
                        type
                        title
                        desc
                        url
                        target
                        video_id
                        long_desc
                        thumb_30
                        thumb_60
                        thumb_360
                        order
                        ts_created
                        ts_updated
                        is_archived
                        status
                        article {
                                id
                                title
                                module{
                                    id
                                    title
                                    topic {
                                        id
                                        title
                                        section {
                                            id
                                            title
                                        }
                                    }
                                }
                        }
                    }
                }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const updateResource = async (variables) => {
    const query = `
            mutation UpdateResource (
                $id: Int!
                $article_id: Int,
                $type: String,
                $title: String,
                $desc: String!,
                $url: String!,
                $target: Int,
                $order: Int,
                $is_archived: Boolean,
                $status: Int,
                $thumb_360: String,
                $video_id: Int
                ) {
                updateResource (
                    id: $id
                    article_id: $article_id,
                    type:$type,
                    title: $title,
                    desc: $desc,
                    url: $url,
                    target: $target,
                    order: $order,
                    is_archived: $is_archived,
                    status: $status,
                    thumb_360: $thumb_360
                    video_id: $video_id
                    ) {
                        id
                        article_id
                        type
                        title
                        desc
                        url
                        target
                        video_id
                        long_desc
                        thumb_30
                        thumb_60
                        thumb_360
                        order
                        ts_created
                        ts_updated
                        is_archived
                        status
                        article {
                                id
                                title
                                module{
                                    id
                                    title
                                    topic {
                                        id
                                        title
                                        section {
                                            id
                                            title
                                        }
                                    }
                                }
                        }
                    }
                }
        `;

    let result = await queryAndVariables(query, variables);

    return result;
};

export const deleteResource = async (variables) => {
    const query = `
        mutation DeleteResource($id: Int!){
            deleteResource(id: $id) 
        }
    `;

    let result = await queryAndVariables(query, variables);

    return result;
};
// SideBarLeaningAdmin Calls

export const topicsNoSections = async (signal) => {
    const query = `
            query {
                topicsNoSection{
                    id
                    title
                    order
                    short_desc
                    thumb_360
                    is_archived
                    ts_created
                    module{
                        id, title, desc, order, thumb_360, topic_id
                        articles{
                            id, title, desc, video_id, order, duration
                        }
                    }
                }
            }
        `;

    let result = await onlyQuery(query, signal);

    return result;
};
